const pages = {
  login: {
    title: "Intranet",
    subtitle: "Seu portal de informações, serviços e muito mais...",
    logo: "https://policlinicaposse.org.br/wp-content/uploads/2023/05/logo-posse.png",
  },
};

const global = {
  api_url: "https://apiintranet.docx.com.br",
  api_sendmail_url: "https://sendmail.docx.com.br/send",
  //api_url: 'http://localhost:8090',
  color: {
    bg: "linear-gradient(138deg,#13aa52,#00662b 90%)",
    "bg-invert": "linear-gradient(138deg,#00662b,#13aa52 90%)",
  },
};

export const constants = {
  pages,
  global,
};
